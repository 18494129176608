<template>
  <div class="signDetail">
    <p>资产要素概要</p>
    <div class="signDetailCon">
      <ul>
        <li>
          <span>债券资产名称</span>
          <span>{{detailData.productName}}</span>
        </li>
        <li>  
          <span>历史业绩比较基准</span>
          <span>{{detailData.totalRate}}%</span>
        </li>
        <!-- <li>  
          <span>业绩补贴</span>
          <span>{{detailData.floatRate}}%</span>
        </li> -->
        <li>  
          <span>收益起算日</span>
          <span>{{detailData.valueDateDesc}}</span>
        </li>
        <li>  
          <span>债券资产到期日</span>
          <span>{{detailData.productDueDateDesc}}</span>
        </li>
        <li>  
          <span>分配方式</span>
          <span>{{detailData.cashStyleDesc}}</span>
        </li>
      </ul>
    </div>
    <div class="book">
      <ul> 
        <li @click="xieyi()">
          <span>《产品说明书》</span>
          <span><van-icon name="arrow" /></span>
        </li>
      </ul>
    </div>
    <div class="signButton" v-if="isShow">
      <span @click="cancel()">取消签约</span>
      <span @click="singing()">下一步</span>
    </div>
  </div>
</template>
<script>
import { getInitProduct,isRiskLevel,canaelReserve} from "../services/proApi";
import { Dialog } from 'vant';
export default {
  data() {
    return {
       detailData:{ },
       productId:"",
       reserveId:'',
       signType:'',
       isShow:false,
    };
  },
  created: function() {
    var _this = this;
    var productId=_this.$route.query.productId;
    var reserveId=_this.$route.query.reserveId;
    var signType=this.$route.query.signType;
    _this.productId=productId;
    _this.reserveId=reserveId;
    _this.signType=signType;
    _this.isRiskLevel(reserveId);//请求详情接口
   if(signType==0){
     _this.isShow=true;
   }

  },
  methods: {
     async isRiskLevel(){//风险测评
      const _this=this;
       const datas = await isRiskLevel({reserveId:this.reserveId});
       //
        if(datas.code==1){
          if(datas.data.isEvaluate==1){//isEvaluate为1时表示已经测评过，并且签章完
             //风险测评等级匹配
            this.getInitProduct();
          }else{
            if(datas.data.signStatus==0){//提示去签章
              Dialog.alert({
                title: '请完成风险测评后操作',
                confirmButtonText:'知道了',
                message:'您尚未完成风险测评签署，请注意查看短信，点击短信中的链接，完成电子签约！',
              })
              .then(() => {
              })
            }else{
               //不存在测评结果
               _this.dailog('请完成风险测评后操作','去测评','为保障您的权益，购买风险匹配的产品，请完成风险测评后继续','/riskAssessment');
            }
          }
        }else if(datas.code==2){
           //不存在测评结果
          _this.dailog('请完成风险测评后操作','去测评','为保障您的权益，购买风险匹配的产品，请完成风险测评后继续','/riskAssessment');
        }else{
          _this.dailog('请完成风险测评后操作','去测评',datas.msg,'/riskAssessment');
        }
     
    },
    async getInitProduct(){//初始化接口
      const datas = await getInitProduct({reserveId:this.reserveId});
      if (datas.code === 1) {
        this.detailData = datas.data;
      }  
    },
    cancel(){//取消
      Dialog.alert({
        title: '温馨提示',
        confirmButtonText:'确定',
        message:'您确定取消签约该产品吗？',
      })
      .then(() => {
        this.canaelReserve();
      })
    },
    async canaelReserve(){//取消接口
      const datas = await canaelReserve({reserveId:this.reserveId});
        if (datas.code === 1) {
         this.$router.push({
            path: '/signList',
          });
        } 
    },
    xieyi(){//协议 
      location.href = 'https://api.liangliangsz.tech/api/product/sign/getSignProtocol?reserveId='+this.reserveId+'&typeId=9'
    },
    
    dailog(title,btn,msg,path){
    Dialog.alert({
      title: title,
      confirmButtonText:btn,
      message:msg,
    })
    .then(() => {
      this.$router.push({
        path: path,
        query: {
          reserveId: this.reserveId,
        }
      });
    })
  },
   singing(){
       this.$router.push({
        path: '/sign',
        query: {
          productId:this.productId,
          reserveId: this.reserveId,
        }
      });
   }
  },
};
</script>

<style scoped lang="scss">
.signDetail{
  p{text-align: center;font-size:1.2rem;padding:1.5rem 0;color:#333}
  .signDetailCon{
    li{
       span:last-child{color:#666}
    }
  }
    ul{
      background: #fff;padding:0 1rem;
      li{
        height:4rem;
        line-height:4rem;
        font-size:0.9rem;
        display: flex;justify-content: space-between;
        border-bottom:0.01rem solid #e9e9e9;
      }
    }
  
  .book{
    margin-top:1rem;
  }
  .signButton{
    position: fixed;
    bottom: 0;
    width: 100%;
    height:3rem;
    line-height:3rem;
    background: #fff;
    font-size: 0.9rem;
    span{display: inline-block;width: 50%;text-align: center;}
    span:last-child{background: #356BFF;color:#fff;}
  }
}
</style>
<style lang='css'>


</style>
